






















































import { Component } from "vue-property-decorator";
import { ButtonCpt } from "@/core/components/common";
import AppVue from "@/AppVue.vue";

import { AccountService, ApiService } from "@/core/services";

@Component({
  components: {
    ButtonCpt,
  },
})
export default class WelcomeView extends AppVue {
  anySlots = true;

  async created() {
    const validateAuthentication = await this.$msal.validateAuthentication();
    if (validateAuthentication) {
      if (this.isStaffOrAdmin) {
        this.$router.push("/");
      } else {
        if (AccountService.enforceApplication()) {
          this.$router.push("/application");
        } else {
          this.$router.push("/user/activity");
        }
      }
    } else {
      this.anySlots = (await ApiService.training().anyAvailableSlotsForThisAcademicYear()).data;
    }
  }

  async login() {
    await this.$msal.login();
  }

  get logoUrl() {
    return require("@/assets/logo.png");
  }
}
